
import {IModalContent} from "../IModalContent";
import BaseModalContent from "./BaseModalContent";

export default class ConfirmModal extends BaseModalContent implements IModalContent{



    constructor() {
        super();
        this.modalContentClass = 'confirm_modal_content'

    }

    protected getTemplate(params: any = null): string {
        return `<div>
        <h1 class="typo_head_3">${params.headline}</h1>
        <div class="type_copy">${params.text}</div>
        <div class="buttons">
        <div class="small_button button_ok">Ok</div>
        <div class="small_button button_cancel">Abbrechen</div>
        </div>
    </div>`

    }

    protected postRender(params: any = null) {
        super.postRender(params);
        this._element.querySelector('.button_ok').addEventListener('click', () => {
            params.confirmResolveFunction(true)
        })
        this._element.querySelector('.button_cancel').addEventListener('click', () => {
            params.confirmResolveFunction(false)
        })
    }
}
