import LocalStorageUtil from "../utils/LocalStorageUtil";


export default class BookmarkStorageController {

    private static bookmarkStorage: LocalStorageUtil<string[]>
    private static _bookmarks: string[]


    public static init() {

        this.bookmarkStorage = new LocalStorageUtil<string[]>('bookmarks')
        this._bookmarks = this.bookmarkStorage.restoreAs() || []
    }

    public static get bookmarks():string[] {
        return this._bookmarks
    }

    public static addBookmark(slug:string):void {
        if (!this.isBookmarked(slug)) {
            this._bookmarks.push(slug)
        }
        this.bookmarkStorage.save(this._bookmarks)
    }

    public static removeBookmark(slug:string): void {
        this._bookmarks.splice(this._bookmarks.indexOf(slug),1)
        this.bookmarkStorage.save(this._bookmarks)
    }

    public static isBookmarked(slug:string): boolean {
        return this._bookmarks.indexOf(slug) >= 0
    }



}