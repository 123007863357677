import {http, HttpResponse} from "../utils/HttpRequest";
import {NamedLogger} from "../utils/NamedLogger";
import LocalStorageUtil from "../utils/LocalStorageUtil";
import BookmarkButtonController from "./BookmarkButtonController";
import BookmarkStorageController from "./BookmarkStorageController";
import {createDeflateRaw} from "zlib";
import DomUtil from "../utils/DomUtil";
import HeaderBookmarkIconController from "./HeaderBookmarkIconController";

const logger:NamedLogger = NamedLogger.getLogger('BookmarkResultContainer')

export default class BookmarkResultContainer {

    private container:HTMLElement
    private cardsContainer:HTMLElement

    constructor(container:HTMLElement) {

        this.container = container
        this.setup()


    }
    /*
    private addRemoveButton(profileCard:HTMLElement): void {

        const deleteButton:HTMLElement = document.createElement('i')
        deleteButton.attributes['aria-hidden'] = true
        deleteButton.attributes['role'] = "img"

        deleteButton.classList.add('icon')
        deleteButton.classList.add('icon-close')
        deleteButton.classList.add('profile_bookmarks_result__card_remove_btn')
        profileCard.appendChild(deleteButton)

        deleteButton.addEventListener('click', () => {
            BookmarkStorageController.removeBookmark(profileCard.dataset.slug)

            profileCard.remove()

            HeaderBookmarkIconController.updateIcon()

            if(BookmarkStorageController.bookmarks.length === 0) {
                this.showNoBookmarksAvailable()
            }

            this.updateCardContainerClass()
        })
    } */

    private useToggleButtonAsDelete(profileCard:HTMLElement): void {
        const deleteButton:HTMLElement = profileCard.querySelector('.profile__bookmark_button')

        if (deleteButton) {
            // set button state to marked
            BookmarkButtonController.toggleBookmarkedStatus(deleteButton, true)

            deleteButton.addEventListener('click', () => {
                BookmarkStorageController.removeBookmark(profileCard.dataset.slug)

                profileCard.remove()

                HeaderBookmarkIconController.updateIcon()

                if(BookmarkStorageController.bookmarks.length === 0) {
                    this.showNoBookmarksAvailable()
                }

                this.updateCardContainerClass()
            })
        }

    }

    private showNoBookmarksAvailable() {
        DomUtil.showElement(this.container.querySelector('.profile_bookmarks_result__no_results'))
    }

    async setup() {

        // get bookmarks from local storage
        try {
            BookmarkStorageController.init()
        }
        catch (e) {
            // can fail if localstorage is not available
            this.container.innerHTML = 'Bitte Cookies erlauben um diese Funktion nutzen zu können.'
            return
        }

        // load bookmarks
        const data:any =  JSON.stringify({"profile_slugs": BookmarkStorageController.bookmarks})
        const response:HttpResponse<any> = await http<any>(new Request('/api/profiles_by_slug/', {method:'POST', body: data}));

        // container for the profile cards
        this.cardsContainer = this.container.querySelector('.profile_bookmarks_result__cards')

        // add card for each profile
        for (const profile of response.parsedBody) {

            this.cardsContainer.insertAdjacentHTML( 'beforeend', profile )
            const profileElement: HTMLElement =  this.cardsContainer.lastChild as HTMLElement
            // add remove Button
            this.useToggleButtonAsDelete(profileElement)
        }

        if (response.parsedBody.length === 0) {
            this.showNoBookmarksAvailable()
        }

        this.updateCardContainerClass()
    }

    private updateCardContainerClass() {
        this.cardsContainer.classList.toggle('profile_bookmarks_result__cards__single', (this.cardsContainer.children.length === 1))
    }
}
