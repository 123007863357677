import {http, HttpResponse} from "../../utils/HttpRequest";

enum URL {
    GET_USER_REFERENCES = "/api/user_references",
    UPDATE_REFERENCE = "/api/user_reference"
}

export default class ReferenceAPI {

    private _csrf_token: string;

    constructor(csrf_token: string) {

        this._csrf_token = csrf_token;
    }

    public async getUserReferences(): Promise<{ id: string, name: string, color: string }[]> {

        const response: HttpResponse<any> = await http<any>(new Request(URL.GET_USER_REFERENCES));
        if (response.ok) {
            const references = response.parsedBody;
            return references;
        } else {
            return [];
        }
    }

    public async addOrUpdateReference(data: FormData):  Promise<{ success:boolean, data:any, status_code?:any }> {

        const response = await fetch(URL.UPDATE_REFERENCE, {
            method: "POST",
            body: data,
            headers: this.getHeader(false)
        });
        if (response.ok) {
               return {success: true, data: await response.json(), status_code: response.status}
        } else {
            return {success: false, data: await response.json(), status_code: response.status};
        }
    }

    public async deleteReference(id): Promise<boolean> {

        return new Promise(async (resolve) => {
            const response: HttpResponse<any> = await http<any>(new Request(URL.UPDATE_REFERENCE, {
                method: 'DELETE',
                headers: this.getHeader(),
                body: JSON.stringify({id: id})
            }));

            if (response.ok) {
                resolve(true);
            } else {
                resolve(false);
            }
        })
    }


    private getHeader(json:boolean = true) {
        return new Headers({
           // 'Content-Type': (json) ? 'application/json' :  'multipart/form-data',
            'X-CSRFToken': this._csrf_token
        });
    }

    private getBody(id, checked) {
        return JSON.stringify({
            id: id,
            save: '' + checked
        })
    }


}
