import {ToolboxUtil} from "../utils/ToolboxUtil";

export default class AlertController {

    protected static getTemplate(params: any): string {

        const template: string =
            `<div class="alert ${params.type}" >
                <div>
                    ${params.message}
                </div>
              </div>`
        return template
    }

    private static rootElement:HTMLElement

    public static init(root:HTMLElement): void {


        const container:HTMLElement = document.createElement('div')
        container.id = 'alert-container'
        root.appendChild(container)

        AlertController.rootElement = container
    }


    public static async showAlert(message: string, type:"success"|"error",  delay: number = 1000) {


        AlertController.rootElement.insertAdjacentHTML('beforeend', AlertController.getTemplate({message: message, type: type}))
        let alert:HTMLElement = <HTMLElement>AlertController.rootElement.lastChild

        alert.addEventListener("click", () => {
            AlertController.removeAlert(alert)
        })

        await ToolboxUtil.delay(delay)
        AlertController.removeAlert(alert)

    }

    public static removeAlert(alert:HTMLElement) {
        try {
            alert.remove()
        }
        catch (e) {
            // do nothing
        }

    }


}
