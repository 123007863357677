import DomUtil from "../utils/DomUtil";
import {NamedLogger} from "../utils/NamedLogger";

interface IResizeImageOptions {
    maxHeight: number;
    maxWidth: number;
    file: File;
}

type Dimensions = {
    width: number;
    height: number;
}

const logger:NamedLogger = NamedLogger.getLogger('ImagePreviewWidget', false);

export default class ImagePreviewWidget {

    private static already_initiated_widgets:any[] = []


    public static init() {

        logger.log("init preview image widgets")
        try {
            const preview_image_widgets: NodeListOf<HTMLElement> = document.querySelectorAll('.image_preview_widget');

            for (const widget of preview_image_widgets) {

                if (this.already_initiated_widgets.indexOf(widget) === -1) {

                    const type:string = widget.dataset.imageType
                    const imageWidth:number = parseInt(widget.dataset.imageWidth) || 400
                    const imageHeight:number = parseInt(widget.dataset.imageHeight) || 400

                    const imageDimensions:Dimensions = {width: imageWidth, height: imageHeight}

                    logger.log("type ", type, "width", imageWidth, "height", imageHeight)

                    const form: HTMLFormElement = widget.closest('form');

                    const file_selector: HTMLInputElement = widget.querySelector('input[type="file"]');
                    const uploadButton: HTMLElement = widget.querySelector('.image_preview_widget__tools');

                    uploadButton.addEventListener('click', (event) => {
                        file_selector.click();
                    });

                    file_selector.addEventListener('change', (event) => {
                        this.handleFileSelect(event, widget, form, file_selector, imageDimensions);
                    })
                    this.already_initiated_widgets.push(widget)
                }

            }
        } catch (e) {
            logger.log("ImagePreviewWidget could not be initiated ");
        }

    }

    private static handleFileSelect(event, widgetContainer: HTMLElement, form: HTMLFormElement, file_selector:HTMLInputElement, resizeDimensions:Dimensions) {

        var files = event.target.files; // FileList object

        // get first file
        const f: File = files[0];

        this.resizeImage({
            file: f,
            maxWidth: resizeDimensions.width,
            maxHeight: resizeDimensions.height,

        })


            .then(function (resizedImageResult) {

                const textBox: HTMLElement = widgetContainer.querySelector('.image_preview_widget__tools__text');
                textBox.classList.toggle('no_image', false);
                const uploadButton: HTMLElement = widgetContainer.querySelector('.image_preview_widget__tools');
                DomUtil.toggleClass(uploadButton, 'image_preview_widget__tool__show', false);

                const previewImage: HTMLElement = widgetContainer.querySelector('.image_preview_widget__preview_image');
                const image: HTMLImageElement = previewImage.querySelector('img');


                image.src = resizedImageResult['url'];


                var data = new FormData(form);
                if (event.blob && event.url) {
                    data.append(file_selector.name, event.blob);


                }

            }).catch(function (err) {
            logger.error(err);
        });

    }



    private static resizeImage(settings: IResizeImageOptions) {
        const file = settings.file;

        const reader = new FileReader();
        const image = new Image();
        const canvas = document.createElement('canvas');
        const dataURItoBlob = (dataURI: string) => {
            const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            const max = bytes.length;
            const ia = new Uint8Array(max);
            for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], {type: mime});
        };

        const cropResize = () => {

            let width = settings.maxWidth;
            let height = settings.maxHeight;

            let xStart = 0,
                yStart = 0,
                aspectRadio,
                newWidth,
                newHeight;

            aspectRadio = image.height / image.width;

            if (image.height < image.width) {
                //horizontal
                aspectRadio = image.width / image.height;
                newHeight = height,
                    newWidth = aspectRadio * height;
                xStart = -(newWidth - width) / 2;
            } else {
                //vertical
                newWidth = width,
                    newHeight = aspectRadio * width;
                yStart = -(newHeight - height) / 2;
            }


            canvas.width = width;
            canvas.height = height;

            canvas.getContext('2d').drawImage(image, xStart, yStart, newWidth, newHeight);
            let dataUrl = canvas.toDataURL('image/jpeg');
            return {url: dataUrl, data: dataURItoBlob(dataUrl)};
        }



        return new Promise((ok, no) => {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }

            reader.onload = (readerEvent: any) => {
                image.onload = () => ok(cropResize());
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        })
    };


}
