export const renderPill = (type, id, name, color, targetNode) => {
    const template = `
    <span class="ability_pill ability_pill__mode_edit" id="${type}_${id}" style="background-color: ${color}">
        <span class="ability_pill__delete_button" data-db_id="${id}"  data-element_id="${type}_${id}" data-type="${type}" data-action="delete">
        <i class="icon icon-close ability_pill__delete_icon" aria-hidden="true" role="img"></i></span>
        <span class="ability_pill__name">${name}</span>
    </span>
`
    targetNode.insertAdjacentHTML('beforeend', template);

}

