import BookmarkStorageController from "./BookmarkStorageController";
import HeaderBookmarkIconController from "./HeaderBookmarkIconController";


export default class BookmarkButtonController {

    private static bookmarkButtons:NodeListOf<HTMLElement>

    public static init():void {

        // get all bookmark buttons (it would only be one, ... but who knows, if used on a search result for example ...)
        this.bookmarkButtons = document.querySelectorAll('.profile__bookmark_button')

        // set current bookmark state state and listen for click
        for (const button of this.bookmarkButtons) {
            const slug:string = button.dataset.profileSlug
            this.toggleBookmarkedStatus(button,  BookmarkStorageController.isBookmarked(slug))
            button.addEventListener('click', () => {
                this.toggleBookMark(button, slug)
            })
        }
    }

    public static toggleBookmarkedStatus(button: HTMLElement, bookmarked:boolean) {
        button.classList.toggle('bookmarked', bookmarked)
        const icon:HTMLElement = button.querySelector('i')
        icon.classList.toggle('icon-bookmark_marker', !bookmarked)
        icon.classList.toggle('icon-bookmark_marker_full', bookmarked)
    }

    private static toggleBookMark(button:HTMLElement, slug:string): void {
        const isAlreadyBookmarked:boolean = BookmarkStorageController.isBookmarked(slug)
        // invert bookmark state
        this.toggleBookmarkedStatus(button, !isAlreadyBookmarked)

        // update local storage
        if (!isAlreadyBookmarked) {
            BookmarkStorageController.addBookmark(slug)
        }
        else {
            BookmarkStorageController.removeBookmark(slug)
        }

        // update header icon if necessarry
        HeaderBookmarkIconController.updateIcon()
    }



}
