import { ToolboxUtil } from '../utils/ToolboxUtil'
import DomUtil from '../utils/DomUtil'

import {IModalContent} from './IModalContent';

export default class GenericModal {

    private getTemplate() {
        return `<!-- The Modal -->
<div id="generic_modal" class="generic_modal" >
  <div class="generic_modal__content">
    <a title="Schließen" target="" class="icon_link font-link generic_modal__content__close_button"><i class="icon icon-close" aria-hidden="true" role="img"></i></a>
    <div class="generic_modal__content__inner"></div>
  </div>
</div>`
    }

    private _escapeKeyHandler:any = null;
    private _modal:HTMLElement = null;
    private _closeCallback: any = null;
    private _modalContent:IModalContent = null;

    private closing:boolean = false;



    private extraClasses:{modalClass:string, contentClass:string};

    constructor(modalStyleClass:string='', modelContentStyleClass:string='', closCallback: any = null) {

        this._closeCallback = closCallback;

        this.extraClasses = {
            modalClass:modalStyleClass,
            contentClass: modelContentStyleClass
        }

        this._escapeKeyHandler = (event: KeyboardEvent) => {
            if (event.key !== undefined) {
                if (event.key.toLowerCase() === 'escape') {
                    this.closeModal();
                    return;
                }
            }

            if (event.keyCode !== undefined && event.keyCode === 27) {
                this.closeModal();
                return;
            }
        }


    }

    public updateModalContent(content:IModalContent):void {
        const modalInnerContent: HTMLElement = this._modal.querySelector('div.generic_modal__content__inner');

        while (modalInnerContent.firstChild) {
            modalInnerContent.removeChild(modalInnerContent.lastChild);
        }

        this._modalContent = content
        modalInnerContent.appendChild(content.element);
    }



    public async closeModal():Promise<any> {
        // allow scrolling again


        return new Promise((resolve) => {
            if(this._modal) {

                if (this._closeCallback != undefined) {
                    this._closeCallback()
                }
                // remove excape handler
                document.removeEventListener('keydown', this._escapeKeyHandler);

                const modalContent: HTMLElement = this._modal.querySelector('div.generic_modal__content');

                // prepare and register transtition end Event
                const transitionEndEventName:string = DomUtil.getTransitionEndEventName();

                const transitionEndHandler:any = () => {

                    // destroy modal content

                    this._modalContent.destroy()

                    if(this._modal) {
                        this._modal.removeEventListener(transitionEndEventName, transitionEndHandler);
                        this._modal.parentElement.removeChild(this._modal);
                        this._modal = null;
                    }
                    document.body.style.overflow = '';


                    this.closing =false
                    resolve()
                }


                if(!this.closing) {
                    this.closing =true
                    this._modal.addEventListener(transitionEndEventName, transitionEndHandler);

                    // fade out modal
                    modalContent.style.opacity = '0';
                    modalContent.style.transform = 'scale(0.8)';
                    this._modal.style.opacity = '0';
                }
            }
            else {
                resolve()
            }
        })


    }

    public async openModal(content:IModalContent) {

        // disable scrolling
        document.body.style.overflow = 'hidden';

        if(this._modal) {
            await this.closeModal();
        }


        // create modal
        document.body.insertAdjacentHTML('beforeend', this.getTemplate())
        this._modal = document.getElementById('generic_modal');

        if(this.extraClasses.modalClass) this._modal.classList.add(this.extraClasses.modalClass);

        // add current content to modal
        this.updateModalContent(content);

        // register event handler


        // handle escape press
        document.addEventListener('keydown', this._escapeKeyHandler);

        // close button
        const closeButton = this._modal.querySelector('a.generic_modal__content__close_button');
        closeButton.addEventListener('click', () => {
            this.closeModal();
        });

        // show the modal
        this._modal.style.display = 'flex';

        // fade in content and modal
        const modalContent: HTMLElement = this._modal.querySelector('div.generic_modal__content');
        if(this.extraClasses.contentClass)  modalContent.classList.add(this.extraClasses.contentClass);
        if(content.modalContentClass)  modalContent.classList.add(content.modalContentClass);

        // need a bit delay
       return ToolboxUtil.delay(50).then(() => {
            modalContent.style.opacity= '1';
            modalContent.style.transform= 'scale(1)';
            if(this._modal) {
                this._modal.style.opacity = '1';
            }

        })
    }

}
