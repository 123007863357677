import GenericModal from "../modal/GenericModal";

export default class ModalController {
    static modal: GenericModal

    public static init() {
        this.modal = new GenericModal()
    }




}
