export default class SearchFormSelectController {

  private categorySelectBox:HTMLSelectElement;
  private abilitiesSelectBox:HTMLSelectElement;
  private form:HTMLFormElement;

  static ABILITIES_API_URL:string = '/api/abilities/{category_id}/'

  constructor(searchFormContainer:HTMLDivElement) {
      this.form = searchFormContainer.querySelector('form')

      this.categorySelectBox = searchFormContainer.querySelector('select#category')
      this.abilitiesSelectBox = searchFormContainer.querySelector('select#ability')

    if(this.categorySelectBox && this.abilitiesSelectBox) {
        this.init();
    }

  }

  private init(): void {


    // listen to category changes
    this.categorySelectBox.addEventListener('change', () => {
      this.updateAbilitiesByCategory(parseInt(this.categorySelectBox.value));
    })

    // listen to ability changes
    this.abilitiesSelectBox.addEventListener('change', () => {
      this.performSearch(this.categorySelectBox.value, this.abilitiesSelectBox.value)
    })
  }

  private performSearch(categoryID:string, abilityID:string) {

    const formData:FormData = new FormData(this.form)
    this.form.submit()
  }

  private async updateAbilitiesByCategory(category: number, selectedAbilityID: string = ''): Promise<void> {
    const response = await fetch(SearchFormSelectController.ABILITIES_API_URL.replace('{category_id}', category+''))
    const abilities = await response.text()

    this.renderAbilitiySelectBox(JSON.parse(abilities), selectedAbilityID);
  }

  private renderAbilitiySelectBox(abilities: { id: string; name: string; count:any; }[], selectedAbilityID: string = ""):void {

    const placeholderText:string = this.abilitiesSelectBox.dataset.placeholder
    this.abilitiesSelectBox.innerHTML = `<option disabled selected>${placeholderText}</option>`

    for (let ability of abilities) {
      const disabled:string = (ability.count == 0) ? 'disabled': ''
      const selected:string = (ability.id == selectedAbilityID) ? 'selected': ''

      this.abilitiesSelectBox.innerHTML+= `<option value="${ability.id}" ${disabled} ${selected}>${ability.name}</option>`
    }

  }
}
