import {IModalContent} from "./IModalContent";
import ConfirmModal from "./content/ConfirmModal";

export default class ModalContentFactory {

  static getModalContentByType(type: string, params?:any):IModalContent {

    const content:HTMLElement = document.createElement('div')
    let modalContent:IModalContent

    switch (type) {
      case 'confirm':
        modalContent = new ConfirmModal()
        modalContent.render(content, params)

        break
      default:
        throw new Error('undefined modal type')
        break
    }
    modalContent.type = type
    return modalContent
  }
}
