export default class LogoutController {


    constructor() {

        // add event listener on all logout buttons
        const logoutLinks:NodeListOf<HTMLAnchorElement> = document.querySelectorAll('[data-button="logout"]');
        for (let link of logoutLinks) {
            link.addEventListener('click', (event)=> this.handleLogout(event, link));
        }
    }

    private handleLogout(event:MouseEvent, link:HTMLAnchorElement) {

        // stop normal behaviour
        event.stopImmediatePropagation();
        event.preventDefault();

        // handle extra stuff here

        // redirect to the original url from the logout button
        location.href = link.getAttribute('href');

    }
}
