import WindowUtils from "../utils/WindowUtils";

interface SearchResult {
    abilities: { name: string; }[],
    profiles: { name: string; }[]
}

export class SearchInputController {
    // adapted from https://openfolder.sh/django-tutorial-as-you-type-search-with-ajax
    scheduledFunction: any
    delay_by_in_ms: number
    searchInputId: string
    searchInput: HTMLInputElement
    userInputResultList: string

    hiddenAbilityIDField: HTMLInputElement
    hiddenCategoryIDField: HTMLInputElement

    constructor(searchInputId: string, userInputResultList: string) {
        this.delay_by_in_ms = 700
        this.scheduledFunction = false
        this.searchInputId = searchInputId
        this.searchInput = document.getElementById(this.searchInputId) as HTMLInputElement;

        const userInputForm: HTMLFormElement = document.getElementById("id_user_input_form") as HTMLFormElement

        this.hiddenAbilityIDField = document.createElement('input')
        this.hiddenAbilityIDField.type='hidden'
        this.hiddenAbilityIDField.name='ability'

        this.hiddenCategoryIDField = document.createElement('input')
        this.hiddenCategoryIDField.type='hidden'
        this.hiddenCategoryIDField.name='category'

        userInputForm.appendChild(this.hiddenAbilityIDField)
        userInputForm.appendChild(this.hiddenCategoryIDField)


        // set initial value of search input
        //this.searchInput.value =WindowUtils.getQueryString('user_input');

        this.userInputResultList = userInputResultList
        this.setupSearchListener()
    }

    clearSearchDropdown(): void {
        let inputDropdown = document.getElementById(this.userInputResultList)
        while (inputDropdown.hasChildNodes()) {
            inputDropdown.removeChild(inputDropdown.firstChild);
        }
    }

    createAnchor(label:string):HTMLAnchorElement {

        const anchor:HTMLAnchorElement = document.createElement('a');
        anchor.href='';
        anchor.text=label;
        return anchor;
    }

    private createSubList(label: string, results: any[], targetList:HTMLElement) {

        const rootListNode:HTMLLIElement = document.createElement("li");
        const labelElement:HTMLSpanElement = document.createElement("span")
        labelElement.className="sublist_label"
        labelElement.innerText = label
        rootListNode.appendChild(labelElement)
        targetList.appendChild(rootListNode)

        const subList:HTMLUListElement = document.createElement("ul");
        subList.className = "sublist"
        rootListNode.appendChild(subList)

        if (results && results.length > 0) {

            results.forEach(result => {
                let optionNode = document.createElement("li");
                const anchor: HTMLAnchorElement = this.createAnchor(result.name);
                optionNode.appendChild(anchor)
                anchor.onclick = (event) => {
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    this.searchInput.value = result.name
                    this.hiddenAbilityIDField.value = result.aid || ''
                    this.hiddenCategoryIDField.value = result.cid || ''
                    if(result.url) {
                        window.location.href=result.url
                        return
                    }
                    let userInputForm: HTMLFormElement = document.getElementById("id_user_input_form") as HTMLFormElement
                    userInputForm.submit();
                }
                subList.appendChild(optionNode);
            })
        }
        else {
            let optionNode = document.createElement("li");
            optionNode.innerText = "Leider nichts passendes gefunden"
            subList.appendChild(optionNode);
        }



    }

    fillSearchDropdown(searchResults: SearchResult): void {
        let inputDropdown = document.getElementById(this.userInputResultList)
        this.createSubList('Name', searchResults.profiles, inputDropdown)
        this.createSubList('Skill', searchResults.abilities, inputDropdown)
    }

    search(): void {

        let searchTerm: string =this.searchInput.value || ''
        searchTerm = searchTerm.trim()

        if(searchTerm === '') {
            this.clearSearchDropdown()
            return
        }

        let token: HTMLInputElement = document.getElementsByName('csrfmiddlewaretoken')[0] as HTMLInputElement;
        let payload: string = JSON.stringify({query: searchTerm})

        fetch('/search/', {
            body: payload,
            method: 'POST',
            headers: {
                "X-CSRFToken": token.value,
                "X-Requested-With": "XMLHttpRequest"  // make request.is_ajax work
            },
        })
            .then((response): Promise<SearchResult> => {
                this.clearSearchDropdown()
                return response.json()
            })
            .then((searchResults:SearchResult) => {
                this.fillSearchDropdown(searchResults)
            })
            .catch(error => console.error)
    }

    private setupSearchListener(): void {
        this.searchInput.addEventListener("keyup", (event) => {
            if (this.scheduledFunction) {
                clearTimeout(this.scheduledFunction)
            }

            // setTimeout returns the ID of the function to be executed
            this.scheduledFunction = setTimeout(() => {
                this.search()
            }, this.delay_by_in_ms)
        })
    }
}



