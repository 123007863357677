import ReferenceAPI from "./ReferenceAPI";
import ImagePreviewWidget from "../ImagePreviewWidget";
import ModalController from "../ModalController";
import ConfirmModal from "../../modal/content/ConfirmModal";
import ModalContentFactory from "../../modal/ModalContentFactory";
import AlertController from "../AlertController";

export default class ReferenceAdminController {

    private root:HTMLElement
    private api:ReferenceAPI
    private clonableNode: HTMLElement

    private numField: HTMLInputElement
    private maxNumField: HTMLInputElement
    private addButton: HTMLButtonElement
    private formContainer: HTMLElement

    private FIELDS:string[] = ['name', 'description', 'year', 'url', 'role', 'image']

    constructor(element:HTMLElement) {
        this.root = element

        const token: HTMLInputElement =
            <HTMLInputElement>document.getElementsByName('csrfmiddlewaretoken')[0];
        this.api = new ReferenceAPI(token.value)

        this.start()

        this.hookIntoGlobalFormSubmit()
    }

    private async hookIntoGlobalFormSubmit() {

        const globalForm:HTMLFormElement = document.querySelector('form#onboarding, form#profile_form') as HTMLFormElement
        const globalSubmit =document.querySelector('[form="onboarding"], [form="profile_form"]')
        if (globalSubmit) {
            globalSubmit.addEventListener('click', async (event) => {
                event.stopImmediatePropagation()
                event.preventDefault()
                // console.log("click - globalform", globalForm, globalSubmit)

                // save references first
                for (const form of this.formContainer.children) {
                    const formSaved: { success:boolean; data:any } = await this.saveForm(form as HTMLElement, true)

                    if (!formSaved.success) {
                        return
                    }
                }

                if (globalForm) {
                    // console.log("globalForm", globalForm, globalForm.submit)
                    globalForm.submit.call(globalForm)

                }

            })
        }
    }



    private prepare() {
        const formName: string = "references"

        this.formContainer = this.root.querySelector(".dynamic_formset_content")

        const emptyForm:HTMLElement = this.root.querySelector('.dynamic_formset__empty_form') as HTMLElement

        // move clonableNode out out the form
        const body = document.getElementsByTagName('body')[0]
        body.appendChild(emptyForm)

        this.clonableNode = emptyForm.querySelector('.dynamic_formset__form') as HTMLElement

        // update yearList
        const yearList:HTMLDataListElement = this.root.querySelector('#year_selection')

        const currentYear = new Date().getFullYear()
        yearList.innerHTML = ''
        for (let i=0; i < 20; i++ ) {
            const option:HTMLOptionElement = document.createElement('option')
            option.value = currentYear-i+""
            yearList.appendChild(option)
        }

        this.maxNumField = this.root.querySelector(`#id_${formName}-MAX_NUM_FORMS`)

        // add more button
        this.addButton = this.root.querySelector(".dynamic_formset_add_button")
        this.addButton.addEventListener('click', (event) => {event.stopImmediatePropagation(); event.preventDefault(); this.addReferenceForm()})
    }


    private getFormFields(fieldset:HTMLElement): any[] {

        const formFields: any[] = []

        for (let field of this.FIELDS) {
            const inputID: string = `textarea[id*="-${field}"], input[id*="-${field}"]`
            const input: HTMLElement = fieldset.querySelector(`${inputID}`)
            input.dataset['field'] = field
            formFields.push(input)
        }

        return formFields

    }

    private allFieldsAreEmpty(fieldset:HTMLElement): boolean {
        const fields:any[] = this.getFormFields(fieldset)
        for (const field of fields) {
            if (field.value) {
                return false
            }
        }
        return true
    }

    private validateFieldsOfReferenceFieldset(fieldset:HTMLElement): boolean {
        const fields:any[] = this.getFormFields(fieldset)

        const fieldValidationMap: any = {}

        for (const field of fields) {
            // check each field
            fieldValidationMap[field.id] = field.checkValidity()

            // if not valid -> reportValidity
            if (!fieldValidationMap[field.id]) {
                // ensure the field is focusable
                // open all collapsed things
                const profileReferencesFormsetCheckbox:HTMLInputElement = document.querySelector('#idb_form__accordion_fieldset_references_toggle')
                if (profileReferencesFormsetCheckbox) {
                    profileReferencesFormsetCheckbox.checked = true
                }

                // reference form fielset
                const toggle:HTMLInputElement = fieldset.querySelector('input[id*="reference__fieldset_accordion_reference"]')
                if (toggle) {
                    toggle.checked = true
                }



                field.reportValidity()
                return false
            }
        }
        return true

    }

    private async start():Promise<void> {
        // load user references
        const references = await this.api.getUserReferences()

        this.prepare()

        for (const reference of references) {
            this.addReferenceForm(reference)
        }

        if (references.length === 0) {
            // add empty reference
            this.addReferenceForm()
        }
    }

    private get maxNum():number {
        return parseInt(this.maxNumField.value || '0')
    }

    private get_form_data(container:HTMLElement): FormData {

        const formData: FormData = new FormData()
        const referenceId: string =  container.getAttribute('reference_id')

        if(referenceId) {
            formData.append('id', referenceId)
        }

        const formFields:any[] = this.getFormFields(container)

        for (const formField of formFields) {
            if (formField.getAttribute('type') === 'file') {
                formData.append(formField.dataset['field'],  formField.files[0])
            }
            else {
                formData.append(formField.dataset['field'], (formField as HTMLInputElement).value)
            }
        }

        return formData
    }

    private updateReferenceCount(): void {
        const fieldSetIcons:NodeListOf<HTMLElement> = this.formContainer.querySelectorAll('.reference__fieldset_icon__text')

        for (let i=0; i< fieldSetIcons.length; i++) {
            fieldSetIcons[i].innerHTML = (i+1)+""
        }

        // uppdate addButton visibility
        this.addButton.style.display = (this.formContainer.children.length >= this.maxNum) ? 'none' : 'flex'
    }

    private addReferenceForm(data: any=null): void {

        const newForm:Node = this.clonableNode.cloneNode(true)
        const newFormHTML:HTMLElement = newForm as HTMLElement

        // set data if available
        if (data) {
            // set content
            const fieldsetLabel: HTMLElement = newFormHTML.querySelector('label[for="reference__fieldset_accordion___prefix__toggle"]')
            if (fieldsetLabel && data['name']) {
                fieldsetLabel.innerHTML = data['name']
            }

            const formFields: any[] = this.getFormFields(newFormHTML)
            for (let formField of formFields) {
                if (formField.tagName === 'TEXTAREA') {
                    formField.innerHTML = data[formField.dataset['field']]
                }
                else {
                    formField.setAttribute('value', data[formField.dataset['field']])
                    if (formField.dataset['field']=='image') {
                        // show image
                        const imgTag:HTMLImageElement = newFormHTML.querySelector('.reference_image__image')
                        imgTag.src = data[formField.dataset['field']]
                        const toolsText:HTMLElement = newFormHTML.querySelector('.image_preview_widget__tools__text')
                        toolsText.classList.toggle('no_image', false)
                        const previewTools:HTMLElement =  newFormHTML.querySelector('.image_preview_widget__tools')
                        previewTools.classList.toggle('image_preview_widget__tool__show', false)
                    }
                }
            }
            newFormHTML.setAttribute('reference_id', data['id'])
        }

        // we need a unique name for the fieldset
        let formRegex = RegExp(`__prefix__`,'g');
        let newPrefix:string = `reference-${Math.floor(Math.random() * Date.now())}`

        let newFormString:string = newFormHTML.innerHTML.replace(formRegex, newPrefix)
        newFormHTML.innerHTML = newFormString

        this.formContainer.appendChild(newForm)

        // update image widget handlers
        ImagePreviewWidget.init();

        const buttonContainer:HTMLElement = document.createElement('div')
        buttonContainer.classList.add('reference_form_button_container')

        // add save button
        const saveButton:HTMLElement = document.createElement('div')
        saveButton.innerHTML="Speichern"
        saveButton.classList.add('submit_button')
        saveButton.classList.add('reference_form_submit_button')
        saveButton.addEventListener('animationend',function() {
            this.classList.remove('flash_error');
            this.classList.remove('flash_success');
        });
        saveButton.addEventListener('click', async (e) => {
            e.stopImmediatePropagation()
            e.preventDefault()
            const response:{success:boolean; data: any, status_code?:any} = await this.saveForm(newFormHTML)

            if (response.success) {
                let message:string = "Projekt gespeichert."
                if (response.status_code && response.status_code == 201) {
                    message = "Projekt angelegt."
                }
                if (response.status_code != 304) {
                    message = "Bitte Werte eintragen"

                }
                AlertController.showAlert(message, "success")

            }

        })

        // add delete button
        // add save button
        const deleteBtn:HTMLElement = document.createElement('div')
        deleteBtn.innerHTML="Projekt löschen"
        deleteBtn.classList.add('submit_button')
        deleteBtn.classList.add('reference_form_delete_button')

        buttonContainer.appendChild(saveButton)
        buttonContainer.appendChild(deleteBtn)

        const fieldSetContent: HTMLElement = newFormHTML.querySelector('.reference__fieldset_accordion__content')
        fieldSetContent.appendChild(buttonContainer)




        deleteBtn.addEventListener('click', async (e) => {
            e.stopImmediatePropagation()
            e.preventDefault()
            this.deleteForm(newFormHTML)
        })



        this.updateReferenceCount()



    }

    private async deleteForm(formRoot:HTMLElement) {

        const formData:FormData = this.get_form_data(formRoot)
        const projectName:FormDataEntryValue = formData.get('name')

        const confirmed:boolean = await new Promise(async (resolve, reject) => {
            await ModalController.modal.openModal(ModalContentFactory.getModalContentByType('confirm',
                {headline: "Sind Sie sicher", text: `Soll das Projekt <span class="project_name">${projectName}</span> tatsächlich gelöscht werden?` ,confirmResolveFunction: resolve }))
        })
        await ModalController.modal.closeModal()

        if (confirmed) {
            // check if has an id
            if (formRoot.getAttribute('reference_id')) {
                const deleteSuccess:boolean = await this.api.deleteReference(formRoot.getAttribute('reference_id'))
                if (deleteSuccess) {
                    formRoot.remove()
                }
            }
            else {
                formRoot.remove()
            }
            this.updateReferenceCount()
        }
    }

    private async saveForm(formRoot:HTMLElement, skipIfEmpty:boolean = false):Promise<{success:boolean, data: any, status_code?:any}> {

        if (skipIfEmpty && this.allFieldsAreEmpty(formRoot)) {
            // no changes, noting to save
            return {success: true, data: null, status_code:304}
        }

        // check validity
        if (!this.validateFieldsOfReferenceFieldset(formRoot)) {
            return {success:false, data: null}
        }

        const formData:FormData = this.get_form_data(formRoot)

        const saveResponse:{success:boolean, data: any, status_code?:any} = await this.api.addOrUpdateReference(formData)
        // console.log("save response", saveResponse)

        this.updateFormData(formRoot, saveResponse)


        return saveResponse
    }

    private updateFormData(form:HTMLElement, response:{success:boolean, data: any}) {

        if (response.success && response.data && response.data.id) {
            // update id
            form.setAttribute('reference_id', response.data['id'])

            const fieldsetLabel: HTMLElement = form.querySelector('label[for*="reference__fieldset_accordion_"]')
            if (fieldsetLabel && response.data['name']) {
                fieldsetLabel.innerHTML = response.data['name']
            }
        }
    }

}
