export interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetch(
        request
    );

    try {
        // may error if there is no body
        response.parsedBody = await response.json();
    } catch (ex) {
        throw new Error("JSON parsing error");
        console.log("json parsing error");
    }
    return response;
}

