import DomUtil from "../utils/DomUtil";
import {NamedLogger} from "../utils/NamedLogger";

const logger:NamedLogger = NamedLogger.getLogger('HeaderNaviController', true)

export default class HeaderNaviController {

    private static bookMarkToggle:HTMLElement
    private static navToggle:HTMLElement

    public static init() {
        try {

            this.navToggle = document.querySelector('.header_navi__toggle');
            this.bookMarkToggle = document.querySelector('.header_navi__bookmarks') as HTMLElement
            const header_navi = document.querySelector('.header_navi');
            const nav = document.querySelector('.header_navi__container');

            this.navToggle.addEventListener('mouseover', (event) => HeaderNaviController.doToggle(event, this.navToggle, nav, "open") );
            this.navToggle.addEventListener('mouseout', (event) => HeaderNaviController.doToggle(event, this.navToggle, nav, "close") );
            nav.addEventListener('mouseover', (event) => HeaderNaviController.doToggle(event, this.navToggle, nav, "open") );
            nav.addEventListener('mouseout', (event) => HeaderNaviController.doToggle(event, this.navToggle, nav, "close") );
            // navToggle.addEventListener('click', function(e) { HeaderNaviController.doToggle(e, navToggle, nav); });
            //
            // nav.addEventListener('click', function(e) { HeaderNaviController.doToggle(e, navToggle, nav); });


            this.bookMarkToggle.addEventListener('click', function(e) {
            location.href='/bookmarks'
            });

        } catch (e) {
            //
            logger.error('HeaderNaviController - failed initialization')
        }



    }
    private static doToggle(e, navToggle, nav, mode:string="") {

        if(e.target.classList.contains('header_navi__close')) {
          //  e.preventDefault();
        }

        switch (mode) {
            case "open":
                DomUtil.toggleClass(navToggle, 'expanded', true);
                DomUtil.toggleClass(nav, 'expanded', true);
                break;
            case "close":
                DomUtil.toggleClass(navToggle, 'expanded', false);
                DomUtil.toggleClass(nav, 'expanded', false);
                break;
            default:
            case "open":
                navToggle.classList.toggle('expanded');
                nav.classList.toggle('expanded');
                break;
        }


    }
}
