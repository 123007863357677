import {NamedLogger} from "../utils/NamedLogger";
import BookmarkStorageController from "./BookmarkStorageController";

const logger:NamedLogger = NamedLogger.getLogger('HeaderBookmarkIconController', false)

export default class HeaderBookmarkIconController {

    private static bookMarkToggle: HTMLElement
    private static navToggle: HTMLElement

    public static init() {
        this.bookMarkToggle = document.querySelector('.header_navi__bookmarks') as HTMLElement
        this.updateIcon()
    }

    public static updateIcon() {

        if(!this.bookMarkToggle) {
            return
        }

        const hasBookmarks: boolean = BookmarkStorageController.bookmarks.length > 0

        logger.log('has bookmarks', hasBookmarks,  BookmarkStorageController.bookmarks.length)



        this.bookMarkToggle.classList.toggle('icon-bookmark', !hasBookmarks)
        this.bookMarkToggle.classList.toggle('icon-bookmark_full', hasBookmarks)
    }
}
