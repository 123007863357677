import {http, HttpResponse} from "../utils/HttpRequest";
import DomUtil from "../utils/DomUtil";

export default class LoginModalController {

    private modal:HTMLElement;

    constructor (modal:HTMLElement) {

        this.modal = modal;
        const close_button:HTMLElement = this.modal.querySelector('div.login-modal__close');
        if(close_button) {
            close_button.addEventListener('click', ()=> this.close());
        }

        // get login trigger buttons
        const loginButtons:NodeListOf<Element> = document.querySelectorAll('[data-button="login"]');

        for (let button of loginButtons) {
            button.addEventListener('click', (event)=>
            {
                event.stopImmediatePropagation();
                event.preventDefault();
                this.show()
            });
        }

        const errorText:HTMLElement = this.modal.querySelector('.login-modal__error');

        const inputs = this.modal.querySelectorAll('input');

        inputs.forEach( (input) => {
            input.addEventListener('focus', () => {
                DomUtil.hideElement(errorText);
            })
        })

        const loginButton = this.modal.querySelector('#login-submit');
        loginButton.addEventListener('click', async (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();

            const formElement = this.modal.getElementsByTagName('form')[0];

            if(!formElement.checkValidity()) {
                formElement.reportValidity();
                return;
            }

            const formData = new FormData(formElement)
            const loginResponse:HttpResponse<any> = await http<any>(new Request('/api/login/', {method:'POST', body: formData}));

            if(loginResponse.ok) {
                location.href = loginResponse.parsedBody.redirect_url;
            }
            else {
                errorText.innerText = "Bitte überprüfen sie E-Mail und Passwort und versuchen Sie es erneut.";
                DomUtil.showElement(errorText);
            }
        })
    }

    private stringToHTML(str) {

            var parser = new DOMParser();
            var doc = parser.parseFromString(str, 'text/html');
            return doc.body;
    }


    private formToJSONString( form ) {
        var obj = {};
        var elements = form.querySelectorAll( "input, select, textarea" );
        for( var i = 0; i < elements.length; ++i ) {
            var element = elements[i];
            var name = element.name;
            var value = element.value;

            if( name ) {
                obj[ name ] = value;
            }
        }
        return JSON.stringify( obj );
    }

    public show() {
        this.modal.style.display = "block";
        document.body.style.overflow = 'hidden';
    }

    public close() {
        this.modal.style.display = "none";
        document.body.style.overflow = '';
    }
}
