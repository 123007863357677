import {url} from "inspector";

export default class SocialLinkController {

    static DOMAIN_REGEX = /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/i;


    private socialLinkInputIds = ['#id_facebook_id', '#id_twitter_id',
        '#id_linkedin_id', '#id_instagram_id'];

    constructor() {
        this.socialLinkInputIds.forEach((inputField) => {
            SocialLinkController.addEventListeners(inputField);
        })
    }

    private static addEventListeners(inputField) {
        let socialInput:HTMLInputElement = document.querySelector(inputField);
        // initially generate link if input is not empty

        if (socialInput.value) {
            this.validateLink(socialInput);
        }
        socialInput.addEventListener('input', () => {
            this.validateLink(socialInput);
        })
    }

    private static validateLink(socialInput:HTMLInputElement):void {
        let div = document.querySelector('#social_value_' + socialInput.name);
        if (!div) {
            div = document.createElement('div');
            div.id = "social_value_" + socialInput.name;
        }
        let valueToCheck = socialInput.value;
        const response: string | boolean = this.checkURLValid(socialInput.name, valueToCheck );
        if (response === true) {
            div.innerHTML = ""
        }
        else {
            div.innerHTML = response.toString();
        }

        socialInput.parentNode.insertBefore(div, socialInput.nextSibling);
    }

    private static getDomainFromString(value:string): string {

        const matches = SocialLinkController.DOMAIN_REGEX.exec(value);
        if (matches !== null){
            if (matches.length > 0) {
                return matches[0]
            }
        }
        return null

    }


    private static checkURLValid(fieldName: string, value: string): string | boolean {
        const domains = {
            'linkedin_id': 'linkedin.com',
            'twitter_id': 'twitter.com',
            'facebook_id': 'facebook.com',
            'instagram_id': 'instagram.com',
        }

        const domainToCheck: string = domains[fieldName]

        if (domainToCheck) {
            const domain_only = this.getDomainFromString(value)
            if (domain_only && domain_only.endsWith(domainToCheck)) {
                return true
            }
        }
        return `Ungültige Eingabe. Keine gültige ${domainToCheck} URL`
    }

    private static updateLink(socialInput:HTMLInputElement):void {
        let div = document.querySelector('#social_value_' + socialInput.name);
        if (!div) {
            div = document.createElement('div');
            div.id = "social_value_" + socialInput.name;
        }
        let valueToCheck = socialInput.value;

        if(valueToCheck.startsWith('www.')) {
            valueToCheck= "https://"+valueToCheck;
        }

        let {url, id} = this.getValidUrlAndId(socialInput.name,valueToCheck );
        div.innerHTML = url;

        if(id) {
            socialInput.value =id;
        }
        socialInput.parentNode.insertBefore(div, socialInput.nextSibling);
    }

    private static isHttpOrHttps(url: string) {
        return /(http|https)/.test(url);
    }


    private static getValidUrlAndId(fieldName: string, value: string) {
        const urls = {
            'linkedin_id': 'https://www.linkedin.com/in/',
            'twitter_id': 'https://www.twitter.com/',
            'facebook_id': 'https://www.facebook.com/',
            'instagram_id': 'https://www.instagram.com/',
        }

        const errorMessage: string = 'Ungültige Eingabe. Folgende URL erwartet: ';

        value = value.toLowerCase();
        value = value.trim();


        if(value.length === 0 ){
            return {url: '', id: null};
        }

        if (!SocialLinkController.isHttpOrHttps(value)) {

            return {url: urls[fieldName] + value, id: value};
        }

        if (urls[fieldName]) {

            if( value.startsWith(urls[fieldName])) {
                let id:string = value.replace(urls[fieldName],'');
                return {url: value, id: id};
            }
            else {
                return {url: errorMessage + urls[fieldName], id: value};
            }
        } else {
            return {url: '', id: null};
        }
    }

}
