export default class TextAreaCountController {

    public static init() {

        const textareas: NodeListOf<HTMLTextAreaElement> = document.querySelectorAll("textarea[maxLength]");

        textareas.forEach((textArea) => {

            // add info box
            const info: HTMLSpanElement = document.createElement('span');
            info.classList.add('textara_char_count')
            this.updateCount(textArea, info);
            textArea.parentNode.insertBefore(info, textArea.nextSibling);

            textArea.addEventListener("input", event => {
                this.updateCount(textArea, info);

            });

        });

    }

    private static updateCount(textArea, info) {
        const maxLength = textArea.getAttribute("maxlength");
        const currentLength = textArea.value.length;
        info.innerText = `${currentLength}/${maxLength}`
    }

}
