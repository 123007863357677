import { NamedLogger } from '../utils/NamedLogger'
import {IBaseComponent} from "./IBaseComponent";


export default class BaseComponent implements  IBaseComponent {

  protected logger: NamedLogger

  protected _element: HTMLElement

  protected _rootElement: HTMLElement


  constructor() {
    this.logger =  NamedLogger.getLogger(this.constructor.name)
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getTemplate(params: any = null): string {
    throw 'You need to define a template'
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected postRender(params: any = null):void {
    // pass
  }

  public render(targetNode: HTMLElement, params: any = null) {
    this._rootElement = targetNode
    targetNode.insertAdjacentHTML('beforeend', this.getTemplate(params))
    this._element = <HTMLElement>targetNode.lastChild
    this.postRender(params)
  }

  public get element():HTMLElement {
    return <HTMLElement>this._element
  }

  protected preDestroy() {
    // override this for cleanup stuff
    // e.g. remove eventlisteners
  }

  public destroy(): void {
    this.preDestroy()
    if (this._element && this.element.parentElement) {
      this._element.parentElement.removeChild(this._element);
    }
  }

  public show(displayParam:string = 'block') {
    try {
      this.element.style.display = displayParam
    }
    catch(e) {
      this.logger.error('can not show', e)
    }

  }

  public hide() {
    try {
      this.element.style.display = 'none'
    }
    catch(e) {
      this.logger.error('can not hide', e)
    }

  }

}
