export class LogUtil {

    public static log(...args: any) {
        console.log.apply(console, args);
    }

    public static error(...args: any) {
        console.error.apply(console, args);

    }
}


