import {renderPill} from "./ability/templates/ability_template";
import AbilityApi from "./AbilityApi";
import DomUtil from "../utils/DomUtil";

enum ATTRIBUTE_ID {
    CHECKBOX = "ability__", //<checkbox id=''>Ability</> ...
    PILL_SPAN = "ability_pill_", // <div>X <span id=''>Ability</span>
    DATA_COLOR = 'data-color'
}

enum CLASS {
    PILL_SPAN = "ability_pill", // <div>X<span class=''>Ability</span>....
    PROPOSAL_PILL_DIV = "ability_selection_widget__ability_proposal_pills",
    PILL_DIV = "ability_selection_widget__ability_pills",
    CATEGORY_SELECT = 'ability_selection_widget__category_select',
    CHECKBOX_CONTAINER = 'ability_selection_widget__ability_checkbox_container',
    PROPOSAL_INPUT = "ability_selection_widget__proposal__input",
    PROPOSAL_HEADLINE = "ability_selection_widget__ability_proposal_headline",
    ABILITIES_HEADLINE = "ability_selection_widget__abilities_headline",
    ABILITIES_COUNT = "ability_selection_widget__abilities_count",
    ERROR_MESSAGE = "ability_selection_widget__ability_limit_error"
}

enum PILL_TYPE {
    USER = "user_pill",
    PROPOSAL = "proposal_pill"
}


export default class AbilitiySelectionWidget {

    private _widgetContainer: HTMLDivElement;
    private _api: AbilityApi;

    static MAX_ABILITIES_PER_USER: number = 5;


    constructor(abilityDivElement: HTMLDivElement) {
        this._widgetContainer = abilityDivElement;
        const token: HTMLInputElement =
            <HTMLInputElement>document.getElementsByName('csrfmiddlewaretoken')[0];
        this._api = new AbilityApi(token.value);
        this.initializeController();
        this.initializeProposal();
        this.onDataChanged();
    }

    private getElementByClass(className: string): HTMLElement {
        return this._widgetContainer.querySelector("." + className);
    }


    private initializeController() {

        const selectElement: HTMLSelectElement = <HTMLSelectElement>this.getElementByClass(CLASS.CATEGORY_SELECT);
        const abilityContainer: HTMLElement = this.getElementByClass(CLASS.CHECKBOX_CONTAINER);
        /*
        * Listener for Drop-Down. When the Drop-Down value changes, fetch
        * corresponding select boxes as HTML (abilities according to the category)
        * */
        selectElement.addEventListener('change', async () => {

            if (!selectElement.value) {
                // clear checkboxes
                abilityContainer.innerHTML = '';
                return;
            }
            ;

            const checkBoxHTML: string = await this._api.getCheckBoxesForCategory(selectElement.value);
            abilityContainer.innerHTML = checkBoxHTML;
            this.addCheckBoxListener();
            this.setCheckBoxesChecked();

        });

        /* PILL delete handler */
        const rootDiv: HTMLElement = this.getElementByClass(CLASS.PILL_DIV);
        rootDiv.addEventListener("click", (event) => {
            const targetNode: HTMLElement = event.target as HTMLElement;
            if (targetNode.dataset['action'] == 'delete') {
                this.deleteAbilityPill(targetNode);
            }
        })

        //Append ability pills to DOM
        this.addAbilityPills();
    }

    private initializeProposal(): void {

        this.getAndAddAbilityProposals();

        //Append proposal input to DOM
        const input: HTMLInputElement = this.getElementByClass(CLASS.PROPOSAL_INPUT) as HTMLInputElement;

        // Execute a function when the user releases a key on the keyboard
        input.addEventListener("keyup", (event) => {
            event.preventDefault();
            event.stopPropagation();
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                this.saveAbilityProposal(input);
            }
            return false
        });

        /* Proposal PILL delete handler */
        const proposalAbilitiesDiv: HTMLElement = this.getElementByClass(CLASS.PROPOSAL_PILL_DIV);
        proposalAbilitiesDiv.addEventListener("click", (event) => {
            const targetNode: HTMLElement = event.target as HTMLElement;
            if (targetNode.dataset['action'] == 'delete') {
                this.deleteAbilityPill(targetNode);
            }
        })

        const send_button: HTMLElement = this._widgetContainer.querySelector('.ability_selection_widget__proposal__submit_button');
        send_button.addEventListener('click', () => {
            this.saveAbilityProposal(input);
        })

    }

    private deleteAbilityPill(pillElement: HTMLElement): void {
        const pill_type = pillElement.dataset['type'];
        const db_id = pillElement.dataset['db_id'];
        const element_id = pillElement.dataset['element_id'];



        if (pill_type == PILL_TYPE.USER) {

            this._api.deleteUserAbility(db_id)
                .then(() => {
                    this.setCheckboxChecked(db_id, false);
                    this.removePill(db_id, pill_type);
                });
        } else if (pill_type == PILL_TYPE.PROPOSAL) {

            this._api.deleteAbilityProposal(db_id)
                .then(() => {
                    this.removePill(db_id, pill_type);
                });

        }
    }

    private async saveAbilityProposal(input: HTMLInputElement) {

        if (input.value === '') {
            return;
        }

        if(!this.isAllowedToAddANewAbility) {
            this.showMaxAbilitiesReachedMessage();
            return;
        }


        const proposal: { name: string, id: number } = await this._api.addAbilityProposal(input.value);
        if (proposal) {
            this.addProposalPill(proposal.id, proposal.name);
        }
        input.value = '';
        input.blur();
    }


    private async addAbilityPills() {

        const abilities: { id: string, name: string, color: string }[] = await this._api.getUserAbilities();
        try {
            abilities.forEach((ability) => {
                this.addPill(ability.id, ability.name, ability.color);
            })
        } catch (e) {
            console.error("Error showing abilities", e);

        }
    }

    private async getAndAddAbilityProposals() {
        const abilityProposals: any[] = await this._api.getAbilityProposals();
        abilityProposals.forEach((ability) => {
            this.addProposalPill(ability.id, ability.name);
        })
    }

    /*
    * Listener for abilities checkboxes. When changed > call backend and add/remove ability from user
    * for multiple identical event listeners,
    * see: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Multiple_identical_event_listeners
     */
    private addCheckBoxListener() {
        const checkBoxes = this._widgetContainer.querySelectorAll("input[type='checkbox']");

        checkBoxes.forEach((input: HTMLInputElement) => {
            let id = input.getAttribute("id");
            id = id.replace(ATTRIBUTE_ID.CHECKBOX, "");
            const color = input.getAttribute(ATTRIBUTE_ID.DATA_COLOR);
            input.addEventListener('change', async () => {
                if(input.checked) {
                    if(this.isAllowedToAddANewAbility) {
                        await this._api.addUserAbility(id);
                        this.addPill(id, input.value, color);
                    }
                    else {
                        input.checked = false;
                        this.showMaxAbilitiesReachedMessage();
                    }
                }
                else {
                    await this._api.deleteUserAbility(id);
                    this.removePill(id, PILL_TYPE.USER);
                }

            });

        })
    }


    private addPill(id, name, color) {
        const rootDiv: HTMLElement = this.getElementByClass(CLASS.PILL_DIV);
        if (rootDiv) {
            renderPill(PILL_TYPE.USER, id, name, color, rootDiv);
            this.onDataChanged();
        }
    }

    private addProposalPill(id, name) {
        const rootDiv: HTMLElement = this.getElementByClass(CLASS.PROPOSAL_PILL_DIV);
        if (rootDiv) {
            renderPill(PILL_TYPE.PROPOSAL, id, name, '#646363', rootDiv);
            this.onDataChanged();
        }
    }

    private removePill(id, pill_type: PILL_TYPE) {
        if (!id) return;
        this._widgetContainer.querySelector('#' + pill_type + "_" + id).remove();

        this.onDataChanged();
    }

    private onDataChanged() {

        const proposalPillDiv: HTMLElement = this.getElementByClass(CLASS.PROPOSAL_PILL_DIV);
        const abilitesPillDiv: HTMLElement = this.getElementByClass(CLASS.PILL_DIV);
        const abilitiesCount: HTMLElement = this.getElementByClass(CLASS.ABILITIES_COUNT);

        const proposalHeadline: HTMLElement = this.getElementByClass(CLASS.PROPOSAL_HEADLINE);
        const abilitiesHeadline: HTMLElement = this.getElementByClass(CLASS.ABILITIES_HEADLINE);

        DomUtil.changeVisiblity(proposalHeadline, (proposalPillDiv.children.length > 0));

        const current = proposalPillDiv.children.length + abilitesPillDiv.children.length;
        const max = 5;

        abilitiesCount.innerText = `(${current}/${max})`

        this.hideMaxAbilitiesReachedMessage();

    }

    private getUserAbilityCount() {
        const proposalPillDiv: HTMLElement = this.getElementByClass(CLASS.PROPOSAL_PILL_DIV);
        const abilitesPillDiv: HTMLElement = this.getElementByClass(CLASS.PILL_DIV);
        const currentCount = proposalPillDiv.children.length + abilitesPillDiv.children.length;
        return currentCount;
    }

    private get isAllowedToAddANewAbility(): boolean {
        return this.getUserAbilityCount() < AbilitiySelectionWidget.MAX_ABILITIES_PER_USER;
    }


    /*
      * Get all spans which include the pills (=user abilities)
      * iterate over the spans to grab the according ability_id
      * lookup if according checkbox for the ability exists. The user has the ability => flag
      * the checkbox
      * */
    private setCheckBoxesChecked() {
        const userAbilities = <any>this._widgetContainer.querySelectorAll('.' + CLASS.PILL_DIV + '>span');
        userAbilities.forEach((span: HTMLSpanElement) => {
            let id = span.getAttribute("id").replace(ATTRIBUTE_ID.PILL_SPAN, "");
            // get raw ability_id
            id = id.match(/.*_(\d*)/)[1];
            this.setCheckboxChecked(id, true);
        })

    }

    private setCheckboxChecked(id, checked) {
        let checkbox: HTMLInputElement =
            <HTMLInputElement>this._widgetContainer.querySelector('#' + ATTRIBUTE_ID.CHECKBOX + id);

        if (checkbox) {
            checkbox.checked = checked;
        }
    }

    private showMaxAbilitiesReachedMessage(): void {
        const errorText:HTMLElement = this.getElementByClass(CLASS.ERROR_MESSAGE);
        DomUtil.showElement(errorText);
    }

    private hideMaxAbilitiesReachedMessage(): void {
        const errorText:HTMLElement = this.getElementByClass(CLASS.ERROR_MESSAGE);
        DomUtil.hideElement(errorText);
    }


}
