import {http, HttpResponse} from "../utils/HttpRequest";

enum URL {
    GET_CHECKBOXES = "/api/load_abilities_checkbox/?category=",
    ADD_OR_DELETE = "/api/update_user_abilities/",
    GET_USER_ABILITIES = "/api/get_user_abilities",
    USER_ABILITY_PROPOSALS = "/api/user_ability_proposals"
}

export default class AbilityApi {

    private _csrf_token: string;

    constructor(csrf_token: string) {

        this._csrf_token = csrf_token;
    }


    public async getCheckBoxesForCategory(category: string): Promise<string> {
        return await fetch(URL.GET_CHECKBOXES + category)
            .then(result => {
                return result.text();
            })

    }

    public async getUserAbilities(): Promise<{ id: string, name: string, color: string }[]> {

        const response: HttpResponse<any> = await http<any>(new Request(URL.GET_USER_ABILITIES));
        if (response.ok) {
            const abilities = response.parsedBody;
            return abilities;
        } else {
            return [];
        }
    }


    public async addUserAbility(id): Promise<boolean> {
        return new Promise(async (resolve) => {
            const response: HttpResponse<any> = await http<any>(new Request(URL.ADD_OR_DELETE, {
                method: 'POST',
                headers: this.getHeader(),
                body: JSON.stringify({id: id, save: "true"})
            }));

            if (response.ok) {
                resolve(true);
            } else {
                resolve(false);
            }
        })

    }

    public async deleteUserAbility(id): Promise<boolean> {
        return new Promise(async (resolve) => {
            const response: HttpResponse<any> = await http<any>(new Request(URL.ADD_OR_DELETE, {
                method: 'POST',
                headers: this.getHeader(),
                body: JSON.stringify({id: id, save: "false"})
            }));

            if (response.ok) {
                resolve(true);
            } else {
                resolve(false);
            }
        })

    }


    /* PROPPOSAL STUFF */

    public async getAbilityProposals(): Promise<[]> {
        const response: HttpResponse<any> = await http<any>(new Request(URL.USER_ABILITY_PROPOSALS));
        if (response.ok) {
            const abilities = response.parsedBody;
            return abilities;
        } else {
            return [];

        }
    }

    public async addAbilityProposal(abilityName: string): Promise<{ name: string, id: number }> {

        const response: HttpResponse<any> = await http<any>(new Request(URL.USER_ABILITY_PROPOSALS, {
            method: 'POST',
            headers: this.getHeader(),
            body: JSON.stringify({name: abilityName})
        }));

        if (response.ok) {
            const proposal: { name: string, id: number } = response.parsedBody
            return proposal;
        } else {
            return null;
        }
    }

    public async deleteAbilityProposal(id): Promise<boolean> {

        return new Promise(async (resolve) => {
            const response: HttpResponse<any> = await http<any>(new Request(URL.USER_ABILITY_PROPOSALS, {
                method: 'DELETE',
                headers: this.getHeader(),
                body: JSON.stringify({id: id})
            }));

            if (response.ok) {
                resolve(true);
            } else {
                resolve(false);
            }
        })
    }


    private getHeader() {
        return new Headers({
            'Content-Type': 'application/json',
            'X-CSRFToken': this._csrf_token
        });
    }

    private getBody(id, checked) {
        return JSON.stringify({
            id: id,
            save: '' + checked
        })
    }


}
