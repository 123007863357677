export default class LocalStorageUtil<T = any>  {

    protected static APP_PREFIX: string;
    protected readonly key: string;

    constructor(storageKey: string) {
        if (!LocalStorageUtil.isAvailable()) {
            throw new Error('Local Storage is not available')
        }
        if (!LocalStorageUtil.APP_PREFIX) {
            throw new Error(`App prefix wasn\`t set! \n Set it by using: LocalStorageUtil.setAppPrefix('yourPrefix')`);
        } else {
            this.key = `${LocalStorageUtil.APP_PREFIX}:${storageKey}`;
        }
    }

    public static isAvailable() {
        let storage;
        try {
            storage = window['localStorage'];
            var x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                    // everything except Firefox
                    e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === 'QuotaExceededError' ||
                    // Firefox
                    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                (storage && storage.length !== 0);
        }
    }


    public static setAppPrefix(value: string): void {
        LocalStorageUtil.APP_PREFIX = value;
    }


    public static clearAll(): void {
        const regExp = new RegExp(LocalStorageUtil.APP_PREFIX + ':');
        Object.keys(localStorage).forEach(key => {
            if (regExp.test(key)){
                localStorage.removeItem(key);
            }
        })
    }


    public save(data: T | any): void {
        if (!data) {
            return localStorage.removeItem(this.key);
        }

        // @ts-ignore
        if (typeof data.toJSON === 'function') {
            // @ts-ignore
            localStorage.setItem(this.key, JSON.stringify(data.toJSON()));
        } else {
            localStorage.setItem(this.key, JSON.stringify(data));
        }
    }


    public restoreAs(Type?: T | any, ignoreNoData = false): T | null {
        const data: string | null = localStorage.getItem(this.key);

        if (!data) {
            if (Type && ignoreNoData) {
                return new Type(JSON.parse(data));
            }
            return null;
        } else if (!Type) {
            return JSON.parse(data);
        }

        return new Type(JSON.parse(data));
    }
    
}