import '../scss/style.scss'
import LoginModalController from "./controller/LoginModalController";
import LogoutController from "./controller/LogoutController";
import SocialLinkController from "./controller/SocialLinkController";
import HeaderNaviController from "./controller/HeaderNaviController";
import ImagePreviewWidget from "./controller/ImagePreviewWidget";
import AbilitiySelectionWidget from "./widgets/AbilitiySelectionWidget";
import TextAreaCountController from "./controller/TextAreaCountController";
import SearchFormSelectController from './controller/SearchFormSelectController'
import {SearchInputController} from "./controller/SearchInputController";

import BookmarkResultContainer from "./controller/BookmarkResultContainer";
import BookmarkButtonController from "./controller/BookmarkButtonController";
import LocalStorageUtil from "./utils/LocalStorageUtil";
import HeaderBookmarkIconController from "./controller/HeaderBookmarkIconController";
import BookmarkStorageController from "./controller/BookmarkStorageController";
import ScrollToErrorController from "./controller/ScrollToErrorController";
import ReferenceAdminController from "./controller/reference/ReferenceAdminController";
import ModalController from "./controller/ModalController";
import AlertController from "./controller/AlertController";


const searchInputId = "id_user_input"
const userInputResultList = "id_user_input_suggestions"

LocalStorageUtil.setAppPrefix('idb')

if (document.getElementById(searchInputId) && document.getElementById(userInputResultList)) {

    new SearchInputController(searchInputId, userInputResultList)
}

if (document.querySelector('.search_form_select_box')) {
    new SearchFormSelectController(document.querySelector('.search_form_select_box'))
}

// init LoginModal if it exists
const loginModal: HTMLElement = document.querySelector('div.login-modal');
if (loginModal) {
    new LoginModalController(loginModal);
}

if (document.querySelector('#div_id_facebook_id')) {
    new SocialLinkController();
}

if (document.querySelector('#profile_bookmarks_result')) {
    new BookmarkResultContainer(document.querySelector('#profile_bookmarks_result'));
}

if (document.querySelector('#reference_administraion')) {
    new ReferenceAdminController(document.querySelector('#reference_administraion'))
}


let widget: HTMLDivElement = null;
if (widget = document.querySelector('.ability_selection_widget')) {
    new AbilitiySelectionWidget(widget);
}

ModalController.init()

TextAreaCountController.init();

ImagePreviewWidget.init();

HeaderNaviController.init();


/* handle bookmarks */
// prepare bookmarks from localstorage
BookmarkStorageController.init()

// update navi icon
HeaderBookmarkIconController.init()

// update profile icon
BookmarkButtonController.init()

// try to scroll to error element
new ScrollToErrorController();

AlertController.init(document.getElementsByTagName('body')[0])


// handle logout
new LogoutController();
